import React, { useEffect } from 'react'
import { wrapper } from '@/store/reduxStore'
import { useRouter } from 'next/router';

import { QueryClient, QueryClientProvider } from 'react-query'
import { ThemeProvider } from '@mui/system'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { deviceDetect } from 'react-device-detect'
import Modal from 'react-modal'
import Cookies from 'universal-cookie'
import random from 'lodash/random'
import FBPixelSender from '@/libs/FBPixelSender'

import 'lazysizes/plugins/attrchange/ls.attrchange.js'
import 'lazysizes/plugins/respimg/ls.respimg.js'
import 'lazysizes'
import 'static/css/base.css'

import config from '@/config'
import mixpanel from '@/libs/mixpanel'
import UniversalCookie from '@/libs/UniversalCookie'
import firebase from '@/libs/firebase'
import getPageContext from '@/libs/getPageContext'
import detectBot from '@/libs/detectBot'
import Routes from '@/routes'
import { window, document } from '@/libs/globalUtils'
import SiteDefault from '@/libs/SiteDefault';
import { verifyAuth } from '@/actions/currentUser'
import { setMobileStatus, setRandomNum } from '@/actions/app'
import DefaultHead, { initInterstitial, initGTM, handleFbPixel } from '@/components/DefaultHead'
import ReactGA from 'react-ga4'


if (config.env == 'development' && document) {
  function recordINP(item) {
    console.log(item, window.location.href)
  }
  // const whyDidYouRender = require('@welldone-software/why-did-you-render');
  // whyDidYouRender(React, {
  //   trackAllPureComponents: true,
  // });
  // import('web-vitals').then(({ onINP, onLCP }) => {
  //   // onCLS(console.log);
  //   // onFID(console.log);
  //   // onLCP(console.log);
  //   onINP(recordINP, {reportAllChanges: true});
  // });
}

const queryClient = new QueryClient()

let ErrorBoundary = React.Fragment

if (config.env != 'development') {
  // logger.start()
  // ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)
  console.log = () => {}
}

export function reportWebVitals(metric) {
  switch (metric.name) {
    case 'FCP':
      // handle FCP results
      break
    case 'LCP':
      // handle LCP results
      break
    case 'CLS':
      // handle CLS results
      break
    case 'FID':
      // handle FID results
      break
    case 'TTFB':
      // handle TTFB results
      console.log('⏰ TTFB: ', metric['value'])
      break
    case 'INP':
      // handle INP results (note: INP is still an experimental metric)
      break
    default:
      break
  }
}


function MyApp({ Component, pageProps }) {
  const { currentUser, siteConfig } = pageProps
  // const testAvivid = router.query.testAvivid || randomNum < 20 && isMobileStatus
  const router = useRouter();
  const testAvivid = router.query.testAvivid

  if (siteConfig) {
    SiteDefault.config(siteConfig)
  }


  const _handleAnymindGliaAd = function () {
    router.events.on('routeChangeStart', (url) => {
      if (window.GliaPlayerManager && window.GliaPlayerManager.players) {
        for (let player of Object.values(window.GliaPlayerManager.players)) {
          try {
            player.destroy()
          } catch (exception) {
            console.log(`Glia: ${exception.name}: ${exception.message}`)
          }
        }
      }
      // console.log(`🧪 route change start: ${url}`)
    })

    router.events.on('routeChangeError', (err, url) => {
      // console.log(`❌ route error ${err}: ${url}`)
    })
  }

  const _renderAvivid = function () {
    if (!window) {
      return
    }
    if (!window.AviviD) {
      window.AviviD = {settings:{},status:{}};
      AviviD.web_id = "ifoodie";
      AviviD.category_id = "20200212000001";
      AviviD.tracking_platform = 'likr';
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl+'&timestamp='+new Date().getTime();f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','GTM-W9F4QDN'); (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl+'&timestamp='+new Date().getTime();f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','GTM-MKB8VFG');
    }

    // Self implement history state
    // window.addEventListener("popstate", function(event) {
    //   console.log('🔥 popstate', event)
    //   if (event.state && event.state.state=='ifoodie_sec') {
    //     console.log('🔥 ifoodie state', event)
    //     // alert('cool')
    //   }
    // });

    // window.addEventListener('scroll', function() {
    //   if (!window._setupBackAd1) {
    //     const currentState = history.state;
    //     history.replaceState({...currentState, ...{state: 'ifoodie_sec'}}, "123");
    //     window._setupBackAd1 = true
    //     console.log('✅ Setup complete 1');
    //     return
    //   }
    //   if (!window._setupBackAd2) {
    //     const currentState = history.state;
    //     history.pushState({...currentState, ...{state: 'ifoodie_page'}}, "new page");
    //     // history.pushState({page: 'ifoodie_page', state: 'ifoodie_page'}, "new page");
    //     window._setupBackAd2 = true
    //     console.log('✅ Setup complete 2');
    //     return
    //   }
    // });
  }

  const _renderGTM = function () {
    // Must include for GTM init
    return (
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-WJWQMXS"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
    )
  }

  useEffect(() => {
    // Already init by TagManager but might need to re-init for ReactGA
    ReactGA.initialize('G-2LQZNSZSYS')

    const sendGAEvent = (url) => {
      const searchParams = new URLSearchParams(window.location.search);
      const source = searchParams.get('utm_source');
      const medium = searchParams.get('utm_medium');
      const campaign = searchParams.get('utm_campaign');

      ReactGA.send({
        hitType: 'pageview',
        page: url,
        source: source,
        medium: medium,
        campaign: campaign,
      });

    }

    const handleRouteChange = (url) => {
      if (window.interstitialSlot) {
        googletag.destroySlots([window.interstitialSlot])
        // console.log(`💣 destroy interstitialSlot: `, window.interstitialSlot)
        googletag.cmd.push(function () {
          initInterstitial()
        })
      }
      sendGAEvent(url)
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    sendGAEvent(window.location.pathname);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    // Fix local cookie not consistent bug
    const cookies = new Cookies()
    const sessionid = cookies.get('sessionid')
    if (sessionid == '') {
      cookies.set('sessionid', '', {
        expires: new Date(+0),
      })
    }

    Modal.setAppElement('body')
    _handleAnymindGliaAd()

    // Defer Tag Manager
    // setTimeout(() => {
      // initGTM()
      FBPixelSender.setUserData({
        email: currentUser.email,
        userId: currentUser.id,
      })
      handleFbPixel()
      // Make sure firebase init before app launch
      firebase.initFirebase()


    // }, 1000)
  }, [])

  return (
    <ErrorBoundary>
      <DefaultHead currentUser={currentUser} />
      {
        testAvivid && _renderAvivid()
      }
      {_renderGTM()}
      <GoogleOAuthProvider clientId="1026074557454-1itund4sq4iedepk5j9k2i3aua8oofgf.apps.googleusercontent.com">
        <ThemeProvider theme={getPageContext().theme}>
          <QueryClientProvider client={queryClient}>
            <Component {...pageProps} />
          </QueryClientProvider>
        </ThemeProvider>
      </GoogleOAuthProvider>
    </ErrorBoundary>
  )
}

MyApp.getInitialProps = wrapper.getInitialAppProps(
  (store) =>
    async ({ Component, ctx }) => {
      const { req } = ctx

      UniversalCookie.config({req})

      ctx.store = store

      let localPageProps = {}
      if (req && req.cookies && req.cookies.sessionid) {
        await store.dispatch(verifyAuth())
      }
      const currentUser = store.getState().currentUser
      ctx.currentUser = currentUser

      let isMobileStatus = false
      if (req && req.headers['user-agent']) {
        const { isMobile, osName } = deviceDetect(req.headers['user-agent'])
        const { isBot } = detectBot(req.headers['user-agent'])
        isMobileStatus = Boolean(isMobile)
        const isIOS = osName === 'iOS'
        const isAndroid = osName === 'Android'
        await store.dispatch(
          setMobileStatus({
            isMobile: isMobileStatus,
            isIOS,
            isBot,
            isAndroidOS: isAndroid,
          })
        )
      }

      let randomNum = 0
      if (req) {
        randomNum = random(1, 100)
        await store.dispatch(setRandomNum(randomNum))
      }

      if (Component.authServerSide) {
        const isAuth = Component.authServerSide(currentUser)
        if (!isAuth && ctx.res) {
          ctx.res.writeHead(302, { Location: '/' })
          ctx.res.end()
          return {}
        }
      }

      if (Component.getInitialProps) {
        localPageProps = (await Component.getInitialProps(ctx)) || {}
        localPageProps.url = Routes.match(ctx.asPath)
      }
      if (Component.getServerSideProps) {
        console.log('🎃getServerSideProps', getServerSideProps)
      }
      localPageProps.currentUser = currentUser.toJS()
      if (req) {
        localPageProps.siteConfig = {
          randomNum,
          isMobile: isMobileStatus,
        }
      }
      return { pageProps: localPageProps }
    }
)

export default wrapper.withRedux(MyApp)
