import { CALL_API } from 'redux-api-middleman'
import constantCreator from '@/libs/constantCreator'
import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'
import {
  placeAutocomplete,
} from '@/libs/placeFinder'

const createConst = constantCreator('search')

export const SET_MAPGL_BOUND = createConst('SET_MAPGL_BOUND')
export function setMapGLBound({ sw, ne }) {
  return {
    type: SET_MAPGL_BOUND,
    sw,
    ne,
  }
}

export const GET_KEYWORD_SEARCH_AUTOCOMPLETE = createConst(
  'GET_KEYWORD_SEARCH_AUTOCOMPLETE'
)
export const GET_KEYWORD_SEARCH_AUTOCOMPLETE_SUCCESS = createConst(
  'GET_KEYWORD_SEARCH_AUTOCOMPLETE_SUCCESS'
)
export const GET_KEYWORD_SEARCH_AUTOCOMPLETE_FAILED = createConst(
  'GET_KEYWORD_SEARCH_AUTOCOMPLETE_FAILED'
)
export function getKeywordSuggestions({ input, latlng }) {
  return {
    [CALL_API]: {
      path: '/category/auto_complete/',
      query: omitBy(
        {
          q: input,
          latlng,
        },
        isNil
      ),
      method: 'get',
      sendingType: GET_KEYWORD_SEARCH_AUTOCOMPLETE,
      successType: GET_KEYWORD_SEARCH_AUTOCOMPLETE_SUCCESS,
      errorType: GET_KEYWORD_SEARCH_AUTOCOMPLETE_FAILED,
    },
  }
}

export const GET_RESTAURANT_SUGGESTIONS = createConst(
  'GET_RESTAURANT_SUGGESTIONS'
)
export const GET_RESTAURANT_SUGGESTIONS_SUCCESS = createConst(
  'GET_RESTAURANT_SUGGESTIONS_SUCCESS'
)
export const GET_RESTAURANT_SUGGESTIONS_FAILED = createConst(
  'GET_RESTAURANT_SUGGESTIONS_FAILED'
)
export function getRestaurantSuggestions({ input, latlng, limit = 5 }) {
  return {
    limit,
    [CALL_API]: {
      path: '/restaurant/search/',
      query: omitBy(
        {
          q: input,
          latlng,
        },
        isNil
      ),
      method: 'get',
      sendingType: GET_RESTAURANT_SUGGESTIONS,
      successType: GET_RESTAURANT_SUGGESTIONS_SUCCESS,
      errorType: GET_RESTAURANT_SUGGESTIONS_FAILED,
    },
  }
}

export const CLEAR_RESTAURANT_SUGGESTIONS = createConst(
  'CLEAR_RESTAURANT_SUGGESTIONS'
)
export function clearRestaurantSuggestions() {
  return {
    type: CLEAR_RESTAURANT_SUGGESTIONS,
  }
}

export const CLEAR_KEYWORD_SEARCH_AUTOCOMPLETE = createConst(
  'CLEAR_KEYWORD_SEARCH_AUTOCOMPLETE'
)
export function clearKeywordSuggestions() {
  return {
    type: CLEAR_KEYWORD_SEARCH_AUTOCOMPLETE,
  }
}

export const GET_CITY_SUGGESTIONS = createConst('GET_CITY_SUGGESTIONS')
export const GET_CITY_SUGGESTIONS_SUCCESS = createConst(
  'GET_CITY_SUGGESTIONS_SUCCESS'
)
export const GET_CITY_SUGGESTIONS_FAILED = createConst(
  'GET_CITY_SUGGESTIONS_FAILED'
)
export function getCitySuggestions({ input }) {
  return {
    [CALL_API]: {
      path: '/location/search',
      query: { q: input },
      method: 'get',
      sendingType: GET_CITY_SUGGESTIONS,
      successType: GET_CITY_SUGGESTIONS_SUCCESS,
      errorType: GET_CITY_SUGGESTIONS_FAILED,
    },
  }
}

export const GET_PLACE_AUTOCOMPLETE = createConst('GET_PLACE_AUTOCOMPLETE')
export const GET_PLACE_AUTOCOMPLETE_SUCCESS = createConst(
  'GET_PLACE_AUTOCOMPLETE_SUCCESS'
)
export const GET_PLACE_AUTOCOMPLETE_FAILED = createConst(
  'GET_PLACE_AUTOCOMPLETE_FAILED'
)
export function getPlaceSuggestions({ input }) {
  return async (dispatch) => {
    dispatch({ type: GET_PLACE_AUTOCOMPLETE })
    const results = await placeAutocomplete(input)
    const perdictions = results.slice(0, 5).map((p) => {
      const center = p.coordinate
      return {
        latlng: { lat: center.latitude, lng: center.longitude },
        description: p.displayLines[0],
        secondaryText: p.displayLines[1] || '',
      }
    })
    dispatch({
      type: GET_PLACE_AUTOCOMPLETE_SUCCESS,
      perdictions,
    })
  }
}

export const CLEAR_PLACE_AUTOCOMPLETE = createConst('CLEAR_PLACE_AUTOCOMPLETE')
export function clearPlaceSuggestions() {
  return {
    type: CLEAR_PLACE_AUTOCOMPLETE,
  }
}

export const EXPLORE_RESTAURANTS = createConst('EXPLORE_RESTAURANTS')
export const EXPLORE_RESTAURANTS_SUCCESS = createConst(
  'EXPLORE_RESTAURANTS_SUCCESS'
)
export const EXPLORE_RESTAURANTS_FAILED = createConst(
  'EXPLORE_RESTAURANTS_FAILED'
)
export const EXPLORE_MORE_RESTAURANTS = createConst('EXPLORE_MORE_RESTAURANTS')
export const EXPLORE_MORE_RESTAURANTS_SUCCESS = createConst(
  'EXPLORE_MORE_RESTAURANTS_SUCCESS'
)
export const EXPLORE_MORE_RESTAURANTS_FAILED = createConst(
  'EXPLORE_MORE_RESTAURANTS_FAILED'
)
export function exploreRestaurants({
  sw,
  ne,
  priceLevel,
  offset,
  keyword,
  opening,
  takeout,
  location,
  sublocation,
  limit,
  sortby,
  latlng,
  range,
  isLoadMore,
  restaurantId,
}) {
  return {
    range,
    offset,
    [CALL_API]: {
      path: '/restaurant/explore/',
      method: 'get',
      query: omitBy(
        {
          q: keyword,
          city_name: location,
          area_name: sublocation,
          orderBy: sortby,
          restaurantId,
          latlng,
          sw,
          ne,
          priceLevel,
          offset,
          limit,
          opening,
          takeout,
          range,
        },
        (val) => !val
      ),
      sendingType: isLoadMore ? EXPLORE_MORE_RESTAURANTS : EXPLORE_RESTAURANTS,
      successType: isLoadMore
        ? EXPLORE_MORE_RESTAURANTS_SUCCESS
        : EXPLORE_RESTAURANTS_SUCCESS,
      errorType: isLoadMore
        ? EXPLORE_MORE_RESTAURANTS_FAILED
        : EXPLORE_RESTAURANTS_FAILED,
    },
  }
}


export function mapUserRestaurants({
  priceLevel,
  offset,
  keyword,
  opening,
  limit,
  sortby,
  isLoadMore,
  mapUserId,
}) {
  return {
    offset,
    [CALL_API]: {
      path: '/restaurant/user_map/',
      method: 'get',
      query: omitBy(
        {
          q: keyword,
          orderBy: sortby,
          userId: mapUserId,
          priceLevel,
          offset,
          limit,
          opening,
        },
        (val) => !val
      ),
      sendingType: isLoadMore ? EXPLORE_MORE_RESTAURANTS : EXPLORE_RESTAURANTS,
      successType: isLoadMore
        ? EXPLORE_MORE_RESTAURANTS_SUCCESS
        : EXPLORE_RESTAURANTS_SUCCESS,
      errorType: isLoadMore
        ? EXPLORE_MORE_RESTAURANTS_FAILED
        : EXPLORE_RESTAURANTS_FAILED,
    },
  }
}

export const CACHE_INITIAL_QUERY = createConst('CACHE_INITIAL_QUERY')
export function cacheInitialQuery({ query }) {
  return {
    type: CACHE_INITIAL_QUERY,
    query,
  }
}

export const GET_CATEGORY_SUGGESTIONS = createConst('GET_CATEGORY_SUGGESTIONS')
export const GET_CATEGORY_SUGGESTIONS_SUCCESS = createConst(
  'GET_CATEGORY_SUGGESTIONS_SUCCESS'
)
export const GET_CATEGORY_SUGGESTIONS_FAILED = createConst(
  'GET_CATEGORY_SUGGESTIONS_FAILED'
)
export function getCategorySuggestions({
  sw,
  ne,
  priceLevel,
  offset,
  keyword,
  opening,
  location,
  sublocation,
  limit,
  sortby,
}) {
  return {
    keyword,
    [CALL_API]: {
      path: '/category/suggestions/',
      method: 'get',
      query: omitBy(
        {
          q: keyword || null,
          city_name: location,
          area_name: sublocation,
          sw,
          ne,
          priceLevel,
          offset,
          limit,
          opening,
          orderBy: sortby,
        },
        isNil
      ),
      sendingType: GET_CATEGORY_SUGGESTIONS,
      successType: GET_CATEGORY_SUGGESTIONS_SUCCESS,
      errorType: GET_CATEGORY_SUGGESTIONS_FAILED,
    },
  }
}

export const GET_RECOMMEND_LIST = createConst('GET_RECOMMEND_LIST')
export const GET_RECOMMEND_LIST_SUCCESS = createConst(
  'GET_RECOMMEND_LIST_SUCCESS'
)
export const GET_RECOMMEND_LIST_FAILED = createConst(
  'GET_RECOMMEND_LIST_FAILED'
)
export function getRecommendList({ keyword, city, adminName, limit = 10 }) {
  return {
    [CALL_API]: {
      path: '/restaurant/explore/',
      method: 'get',
      query: omitBy(
        {
          q: keyword,
          city_name: city,
          area_name: adminName,
          order_by: 'rating',
          as_recommend: true,
          limit,
        },
        isNil
      ),
      sendingType: GET_RECOMMEND_LIST,
      successType: GET_RECOMMEND_LIST_SUCCESS,
      errorType: GET_RECOMMEND_LIST_FAILED,
    },
  }
}

export const ON_LOCATION_ERROR = createConst('ON_LOCATION_ERROR')
export function showLocationError({ errorMessage }) {
  return {
    type: ON_LOCATION_ERROR,
    errorMessage,
  }
}
